<template>
  <div class="about">
    <el-tabs v-model="activeName">
      <el-tab-pane label="提现列表" name="1">
        <Withdrawal :nowData="rowData"></Withdrawal>
      </el-tab-pane>
      <el-tab-pane label="提现日志" name="2">
        <Journal :nowData="rowData"></Journal>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/masterApi"//本页面接口
import Withdrawal from '../components/masterChild/Withdrawal'
import Journal from '../components/masterChild/Journal'
export default {
  name: 'brand',
  components:{
    Withdrawal,
    Journal,
  },
  data(){
    return{
      activeName:'1',
      login:false,
      rowData:{
        worker_id:'',
      }

    }
  },
  created() {

  },
  methods:{
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
      axios.getmasterData(form).then((res)=>{
        console.log(res)
        this.tableData=res.data.data
        console.log('this.tableData',this.tableData)
        this.total=res.data.total
      }).catch((err)=>{

      })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    upData(row){
      axios.postediview({id:row.id}).then((res)=>{
        if(res.code==200){
          this.ruleForm=res.data
          this.drawer=true
        }else {
          this.$message.error(res.msg)
        }
      }).catch((err)=>{
        this.$message.error('服务器报错！！请联系管理员')
      })
    },
    onCashList(row){
      this.rowData.worker_id=row.id
      this.withdrawer=true
    },

  }
}
</script>
<style lang="less" >

</style>